@import "~@angular/material/theming";

@include mat-core();

$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink);

$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

:root {
  --brand: "selforder";

  --ion-color-gray-light: #f5f5f5;
  --ion-color-gray-dark: #d4d4d4;
  --ion-color-gray-darkest: #1f1b1c;

  --ion-color-black: #000;
  --ion-color-subtitle: #313131;
  --ion-color-gold: #a38a5f;
  --ion-color-violet: var(--ion-color-primary);

  //main variables
  --background-color: #fff;
  --background-toolbar-color: #f6f0eb;
  --color-h: var(--ion-color-violet);
  --color-p-span: var(--ion-color-subtitle);
  --buttons-border-radius: 28px;
  --buttons-font-family: AppFontBold, sans-serif;
  --buttons-font-size: 18px;
  --buttons-text-transform: uppercase;
  --buttons-text-color: white;
  --account-toolbar-background: var(--background-color);
  --snack-bar-color: white;
  //home-page
  --home-page-card-backround: white;
  --home-page-language-switcher-color-selected: #2c2c31;
  --home-page-title-font-size: 30px;
  --home-page-info-font-size: 16px;
  --home-page-card-borde-radius: 0px;
  --home-page-card-box-shadow: none;
  --home-page-button-font-family: AppFontBold, sans-serif;
  --home-page-button-font-size: 16px;

  //menu-page
  //item-view
  --item-view-background-color: #fff;
  --item-view-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  --item-view-border: 1.5px solid #f6f3f5;

  --item-view-title-font-size: 16px;
  --item-view-title-line-height: 23px;

  --item-view-info-font-size: 12px;
  --item-view-info-line-height: 14px;
  //item-view
  //menu-page

  //modal-info
  --modal-info-title-font-size: 26px;
  --modal-info-title-line-height: 38px;

  --modal-info-desc-font-size: 14px;
  --modal-info-desc-line-height: 18px;

  --item-menge-border: 3px solid #f6f3f5;

  --modal-info-button-font-size: 16px;
  --modal-info-button-text-color: #fff;

  //modal-info

  //order-page
  //order-toolbar
  --order-toolbar-title-font-size: 20px;
  --order-toolbar-title-line-height: 29px;
  //order-toolbar

  //order-list
  --order-list-title-font-size: 16px;
  --order-list-title-line-height: 19px;

  --order-list-option-name-font-size: 16px;
  --order-list-option-name-line-height: 13px;
  --order-list-option-name-color: var(--ion-color-subtitle);

  --order-list-article-price-font-size: 14px;
  --order-list-oarticle-price-line-height: 20px;

  --order-list-order-item-border: var(--item-view-border);
  //order-list

  //promo-code
  --promo-code-border: var(--item-view-border);
  box-shadow: var(--item-view-box-shadow);

  //promo-code

  --order-page-add-text-font-size: 18px;
  --order-page-add-text-line-height: 14px;
  --order-page-add-text-boroder: 0.5px solid #e5e5e5;
  --order-page-add-text-box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.02);
  --order-page-add-text-border-radius: 15px;

  --order-page-footer-btn-text-font-size: 16px;
  --order-page-footer-btn-text-line-height: 19px;
  //order-page

  --menu-modal-name-color: var(--ion-color-primary);

  --quantity-selector-border-width: 1px;
  --quantity-selector-border-color: #ececec;
  --quantity-selector-height: 40px;
  --quantity-selector-background: #fff;
  --quantity-selector-text-color: var(--ion-color-primary);
  --quantity-selector-font-size: 14px;
  --home-preorder-type-button-background: var(--ion-color-primary);
  --preorder-type-chip-border-color: white;

  --payment-modal-item-background: white;
  --payment-modal-checkbox-color: black;
  --font-tusker: Tusker, sans-serif;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-black);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}
